// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appstore-optimering-js": () => import("./../src/pages/appstore-optimering.js" /* webpackChunkName: "component---src-pages-appstore-optimering-js" */),
  "component---src-pages-apputveckling-js": () => import("./../src/pages/apputveckling.js" /* webpackChunkName: "component---src-pages-apputveckling-js" */),
  "component---src-pages-drift-js": () => import("./../src/pages/drift.js" /* webpackChunkName: "component---src-pages-drift-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-sakerhet-js": () => import("./../src/pages/sakerhet.js" /* webpackChunkName: "component---src-pages-sakerhet-js" */)
}

